import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { SharedService } from "./../../global";

@Injectable({
  providedIn: "root",
})
export class UsermanagementService {
  constructor(private http: HttpClient, public sharedService: SharedService) {}

  API_URL = this.sharedService.getGlobalVar();

  addUser(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "/usermanagement/userprofile-create/",
      body,
      { headers: headers }
    );
  }

  getChannelIds() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "channel_list/", { headers: headers });
  }

  getAutocallClients() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "usermanagement/get-autocall-clients/",
      { headers: headers }
    );
  }

  getAutocallDetails(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/create-delete-update-autocall-clients/",
      body,
      { headers: headers }
    );
  }

  updateDeleteAutocalldetails(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/get-autocall-clients/",
      body,
      { headers: headers }
    );
  }

  getUser() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "/usermanagement/userprofile-list", {
      headers: headers,
    });
  }

  getUserList(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "userList/" + userProfileId + "/", {
      headers: headers,
    });
  }

  getClientDetails(id: number,body,current_page) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + 'client_details/?page='+current_page, body, {
      headers: headers,
    });
  }

  addUserProfile(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "UserProfileCreate/", body, {
      headers: headers,
    });
  }
  addSubUserProfile(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "SubUserProfileCreate/", body, {
      headers: headers,
    });
  }

  getUserProfile(id: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "userprofileDetails/" + id + "/", {
      headers: headers,
    });
  }

  getProducts() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "products/", { headers: headers });
  }

  getCompanies(){
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "companies/", { headers: headers });
  }

  getRenewalHistory() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "renewal_history/", {
      headers: headers,
    });
  }

  getDid(id: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "didDetail/" + id + "/", {
      headers: headers,
    });
  }
  updateUserProfileData(id:number, body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.put(
      this.API_URL + "userprofileDetails/" + id + "/",
      body,
      { headers: headers }
    );
  }

  AddProduct(product){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const body = {'name':product}
    return this.http.post(this.API_URL + "addProduct/", body, {
      headers: headers,
    })
  }

  UpdateProduct(id,body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "updateProduct/"+ id + "/", body, {
      headers: headers,
    });
  }

  DeleteProduct(id,body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "deleteProduct/"+ id + "/", body, {
      headers: headers,
    });
  }

  deleteUserProfileData(id) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.delete(this.API_URL + "userprofileDetails/" + id + "/", {
      headers: headers,
    });
  }

  // ======================start user permissions=======================================================

  addUserPermission(id, body, scope?: string) {
    const headers = new HttpHeaders();
    let qParams = new HttpParams()
    if (scope === "auth_user") {
      qParams = qParams.set("scope", "auth_user")
    }
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "addpermission/" + id + "/", body, {
      headers: headers,
      params: qParams
    });
  }

  getUserPermission(id, scope?: string) {
    const headers = new HttpHeaders();
    let qParams = new HttpParams()
    if (scope === "auth_user") {
      qParams = qParams.set("scope", "auth_user")
    }
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "addpermission/" + id + "/", {
      headers: headers,
      params: qParams
    });
  }


  // ======================end user permissions=======================================================

  updateUserData(id, body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.put(this.API_URL + "userDetails/" + id + "/", body, {
      headers: headers,
    });
  }

  getGroupList(id: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "usergroupDetails/" + id + "/", {
      headers: headers,
    });
  }

  getPermissions(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "permissionList/" + userProfileId + "/",
      { headers: headers }
    );
  }

  addUsergroup(body, id) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "UsergroupCreate/" + id + "/", body, {
      headers: headers,
    });
  }
  addSubUserPermission(id, body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "addsubuserpermission/" + id + "/",
      body,
      { headers: headers }
    );
  }

  searchgroupList(val: string, userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UsergroupList/" + userProfileId + "/?search=" + val,
      { headers: headers }
    );
  }
  getgroupList(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UsergroupList/" + userProfileId + "/",
      { headers: headers }
    );
  }

  getSubusergroup(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UsergroupEdit/" + userProfileId + "/",
      { headers: headers }
    );
  }
  updateSubusergroup(body, id) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.put(this.API_URL + "UsergroupEdit/" + id + "/", body, {
      headers: headers,
    });
  }
  deleteUserGroupData(id) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.delete(this.API_URL + "UsergroupEdit/" + id + "/", {
      headers: headers,
    });
  }

  CompanyList() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "usermanagement/getCompanyList/", {
      headers: headers,
    });
  }

  getCompanyUser(name) {
    const headers = new HttpHeaders();
    const body = { name: name };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/getUserbyCompanyName/",
      body,
      { headers: headers }
    );
  }

  getAgentUsers(id) {
    const headers = new HttpHeaders();
    const body = { userId: id };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/getAgentUsers/",
      body,
      { headers: headers }
    );
  }

  addAgentUsers(
    userId,
    name,
    num,
    chatid,
    email,
    password,
    autocall,
    timecondition,
    twofactauthEnabled
  ) {
    const headers = new HttpHeaders();
    const body = {
      name: name,
      num: num,
      chatId: chatid,
      userId: userId,
      email: email,
      password: password,
      autocall: autocall,
      timecondition: timecondition,
      is_2FA_enabled: twofactauthEnabled
    };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/addAgentUsers/",
      body,
      { headers: headers }
    );
  }

  fetchAgentorDelete(id: number, action) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/fetchAgentorDelete/",
      { id: id, action: action },
      { headers: headers }
    );
  }

  ExistAgent(user, name, num) {
    const body = { name: name, num: num, user: user };
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "usermanagement/existagent/", body, {
      headers: headers,
    });
  }

  EditAgentUsers(
    name,
    num,
    chatid,
    id,
    email,
    password,
    autocall,
    timecondition,
    twofactauthEnabled
  ) {
    const headers = new HttpHeaders();
    const body = {
      name: name,
      num: num,
      chatId: chatid,
      id: id,
      email: email,
      password: password,
      autocall: autocall,
      timecondition: timecondition,
      is_2FA_enabled: twofactauthEnabled
    };

    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/editAgentUsers/",
      body,
      { headers: headers }
    );
  }

  getVirtualSipUsers(id) {
    const headers = new HttpHeaders();
    const body = { userId: id };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/getVirtualSipUsers/",
      body,
      { headers: headers }
    );
  }

  toggleAgentOnOff(data: any) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/agent_status_toggle/",
      data,
      { headers: headers }
    );
  }

  generateToken(userId: number){
    const headers = new HttpHeaders().set("Content-Type", "application/json")
    const body = {user_id: userId}
    return this.http.post(this.API_URL + "usermanagement/generateToken/", body, {headers: headers})

  }

  enableAutoCallClient(userProfileId: number){
    const headers = new HttpHeaders().set("Content-Type", "application/json")
    const body = {userProfileId: userProfileId}
    return this.http.post(this.API_URL + "usermanagement/enableAutocallClient/", body, {headers: headers})
  }

  addAgentSchedule(data: any) {
    const headers = new HttpHeaders().set("Content-Type", "application/json")
    return this.http.post(this.API_URL + "usermanagement/agent-schedule/", data, {headers: headers})
  }

  getAgentSchedules(qParams: any[]) {
    const headers = new HttpHeaders().set("Content-Type", "application/json")
    let params = new HttpParams()
    qParams.forEach((param) => {
      params = params.append(param.key, param.value)
    })

    return this.http.get(this.API_URL + "usermanagement/agent-schedule/", {headers: headers, params: params})
  }

  updateAgentSchedule(data: any) {
    const headers = new HttpHeaders().set("Content-Type", "application/json")
    return this.http.put(this.API_URL + `usermanagement/agent-schedule/${data.id}/`, data, {headers: headers})
  }

  deleteAgentSchedule(id: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json")
    return this.http.delete(this.API_URL + `usermanagement/agent-schedule/${id}/`, {headers: headers})
  }

  getCombinedUsers(qParams: any[]) {
    let params = new HttpParams();
    qParams.forEach(param => {
      params = params.set(param.key, param.value);
    });

    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.get(this.API_URL + 'usermanagement/combined-users/', { headers: headers, params: params });
  }

  getSmsProviderData(smsType:string){
    const headers = new HttpHeaders({"Content-Type": "application/json"});
    return this.http.get(this.API_URL + "usermanagement/sms-provider/?smsType="+ smsType, {headers: headers});
  }
}
