export const environment = {

    firebaseConfig: {
        apiKey: "AIzaSyDPGB1450_LRUcQWMBDDoSQaXnRRpGrsms",
        authDomain: "bonvoice-solutions.firebaseapp.com",
        databaseURL: "https://bonvoice-solutions-default-rtdb.firebaseio.com",
        projectId: "bonvoice-solutions",
        storageBucket: "bonvoice-solutions.appspot.com",
        messagingSenderId: "473385320230",
        appId: "1:473385320230:web:10bc66b76d7097df834be7",
        measurementId: "G-XEV62PDVL4"
    },
    production: true,
    title: 'Pre-Production Environment Heading',
    apiURL: 'https://backend.preproduction.bonvoice.com/',
    voiceURL: 'https://backend.preproduction.bonvoice.com/',
    inactivity_logoutTime: 30,
    version: "P07-20241218-001",
};
