import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from '../usermanagement.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { FormGroup, FormArray, Validators, FormControl, FormBuilder } from '@angular/forms';
import { forEach } from 'jszip';
// import { RouterModule, Routes } from '@angular/router'

@Component({
  selector: 'app-agent-users',
  templateUrl: './agent-users.component.html',
  styleUrls: ['./agent-users.component.css']
})

export class AgentUsersComponent implements OnInit {

  submitted = false;
  userData: any;
  UserProfileId: any;
  userName: any;
  agentData: any;
  user: any;
  name: any;
  number: any;
  chatId: any;
  password: any
  email: any;
  // id: number;
  public id: any = 0;
  existedAgent: any;
  autocall: boolean = false;
  timecondition: boolean = false;
  permissions: any;
  autocall_permisson: boolean = false
  public AgentForm: FormGroup;
  toggleOnOff: boolean = true;
  weekdaysArray: any = new Array;
  selectedWeekDay: string = "6";
  selectedStartTime: string = "00:00";
  selectedEndTime: string = "23:59";
  isScheduleEdit: boolean = false;
  selectedSchedule: string = "";
  twofactauthEnabled: boolean = false;

  weekdays: any[] = [
    { "value": 6, "day": "Sunday" },
    { "value": 0, "day": "Monday" },
    { "value": 1, "day": "Tuesday" },
    { "value": 2, "day": "Wednesday" },
    { "value": 3, "day": "Thursday" },
    { "value": 4, "day": "Friday" },
    { "value": 5, "day": "Saturday" }
  ];

  timeError: boolean = false;
  mandatoryError: boolean = false; // Flag for mandatory error

  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private activeroute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(false);


    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      if (element.codename == "cadmin_o_autocallreport") {
        this.autocall_permisson = true;
      }
    }

    this.UserProfileId = this.userData.id;
    this.userName = this.userData.name
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    this.usermanagementservice.getAgentUsers(this.UserProfileId).subscribe(
      (res: any) => {
        this.agentData = res["data"];
      }
    )

    this.AgentForm = new FormGroup({
      name: new FormControl(Validators.required, this.nameValid({ pattern: /^(\d|\w)+$/, msg: 'Space / special Charcaters(!#$%&()*+,-./:;<=>?) are not allowed' })),
      number: new FormControl(Validators.required, this.numberValid({ pattern: /^\+?[0][0-9(),.-]{10}$/, msg: 'Mobile Number is not Valid' })),
      chatId: new FormControl(),
      email: new FormControl(),
      password: new FormControl(),
      autocall: new FormControl(),
      timecondition: new FormControl(),
      twofactauthEnabled: new FormControl(),

    });


  }

  numberValid(config: any) {

    return (control: FormControl) => {
      let urlRegEx: RegExp = config.pattern;
      if (control.value && !String(control.value).match(urlRegEx)) {
        return {
          invalidnumberMsg: config.msg
        };
      }
    };
  }

  nameValid(config: any) {

    return (control: FormControl) => {
      let urlRegEx: RegExp = config.pattern;
      if (control.value && !String(control.value).match(urlRegEx)) {
        return {
          invalidnameMsg: config.msg
        };
      }
    };

  }

  onSubmit() {
    this.submitted = true;
    if (this.AgentForm.valid) {
      var name = this.AgentForm.value.name
      var num = this.AgentForm.value.number
      var chatId = this.AgentForm.value.chatId
      var email = this.AgentForm.value.email
      var user = this.userName
      var password = this.AgentForm.value.password
      var autocall = this.AgentForm.value.autocall
      var timecondition = this.AgentForm.value.timecondition
      var twofactauthEnabled = this.AgentForm.value.twofactauthEnabled
      
      // alert("chatId"+chatId)

      this.usermanagementservice.ExistAgent(user, name, num).subscribe(
        (res: any) => {
          this.existedAgent = res;

          if (this.existedAgent['status'] == 0) {
            this.usermanagementservice.addAgentUsers(this.UserProfileId, name, num, chatId, email, password, autocall, timecondition,twofactauthEnabled).subscribe(
              (res: any) => {

                if (res.status == 0) {
                  Swal.fire('Error', res.message, 'warning')
                }
                else {
                  Swal.fire('Created', res.message, 'success')

                  this.submitted = false;
                  this.usermanagementservice.getAgentUsers(this.UserProfileId).subscribe(
                    (res: any) => {
                      this.agentData = res["data"];
                    }
                  )

                  if (res.data.scheduler_enabled) {
                    this.id = res.data.id
                  } else {
                    this.AgentForm.reset();
                    this.reloadCurrentRoute();

                  }
                }
              })
          }
          if (this.existedAgent['status'] == "1") {
            Swal.fire('Oops..!', 'Please change the Agent Name, Already Exist', 'warning')
          }
          if (this.existedAgent['status'] == "2") {
            Swal.fire('Oops..!', 'Please change the Agent Number, Already Exist', 'warning')
          }
        });
    }

  }


  onEdit(id) {

    this.submitted = true;
    if (this.AgentForm.valid) {
      this.usermanagementservice.EditAgentUsers(this.name, this.number, this.chatId, id, this.email, this.password, this.autocall, this.timecondition, this.twofactauthEnabled).subscribe(
        (res: any) => {

          if (res.status == 0) {
            Swal.fire('Error', res.message, 'warning')
          }
          else {
            Swal.fire('Updated', res.message, 'success')
            this.AgentForm.reset();
            this.submitted = false;
            this.AgentForm.controls['name'].enable();
            this.usermanagementservice.getAgentUsers(this.UserProfileId).subscribe(
              (res: any) => {
                this.agentData = res["data"];
                this.reloadCurrentRoute();
              }
            )

          }


        });
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  fetchAgent(id) {
    this.usermanagementservice.fetchAgentorDelete(id, "fetch").subscribe(
      (res: any) => {
        this.name = res["data"]["name"];
        this.number = res["data"]["number"];
        this.id = res["data"]["id"];
        this.chatId = res["data"]["chat_id"];
        this.email = res["data"]["email"];
        this.password = res["data"]["password"];
        this.timecondition = res["data"]["scheduler_enabled"];
        this.twofactauthEnabled = res["data"]["is_2FA_enabled"];
        this.getAgentSchedules();
      });

  }


  OndeleteAgent(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        console.log("success")
        this.user = this.loginservice.getUserdata(true);
        this.usermanagementservice.fetchAgentorDelete(id, "delete").subscribe(
          (res: any) => {
            Swal.fire('Deleted', 'Agent Successfully Deleted', 'success')
            this.usermanagementservice.getAgentUsers(this.UserProfileId).subscribe(
              (res: any) => {
                this.agentData = res["data"];
              }
            )


          },
          (error: any) => {


            const errorMessage = error.error && error.error.error
              ? error.error.error.replace(/^\[|\]$/g, '')
                .replace(/'/g, '')
                .trim()
              : 'Please try again later.';

            Swal.fire('Error', errorMessage, 'error');

          }
        );
      }
    })
  }

  onTimeConditionChange(event: any) {
    this.timecondition = event.target.checked;
  }


  onToggleOnOff(id: number, status: boolean) {

    const data = {
      agent_id: id,
      status: !status,
      scope: "agent_user"
    }

    this.usermanagementservice.toggleAgentOnOff(data).
      subscribe((res: any) => {
        if (res["status"] == "1") {
          Swal.fire('Updated', res.message, 'success')
        }
        else {
          Swal.fire(res["message"], res.message, 'warning')
        }
      })

  }

  onAddSchedule() {
    const data = {
      agent: this.id,
      weekday: this.selectedWeekDay,
      start_time: this.selectedStartTime + "00",
      end_time: this.selectedEndTime + ":59"
    }

    this.usermanagementservice.addAgentSchedule(data).subscribe((res: any) => {
      if (res.status == "1") {
        Swal.fire("Success", "Agent schedule added successfully", "success");
        this.getAgentSchedules();
        this.selectedWeekDay = "6";
        this.selectedStartTime = "00:00";
        this.selectedEndTime = "23:59";
      } else {
        Swal.fire("Somthing went wrong!", "Failed to add agent schedule", "error");
      }
    })
  }

  getAgentSchedules() {
    const qParams = [
      { key: "scope", value: "by_agent" },
      { key: "agent_id", value: this.id }
    ]

    this.usermanagementservice.getAgentSchedules(qParams).subscribe((res: any) => {
      if (res.status == "1") {
        this.weekdaysArray = res.data.map(item => ({
          ...item,
          day: this.weekdays.find(day => day.value == item.weekday).day
        }));
      }
    })
  }

  onEditAgentSchedule(id: string) {
    const selectedItem = this.weekdaysArray.find(item => item.id === id);
    this.selectedSchedule = id;
    this.selectedWeekDay = selectedItem.weekday;
    this.selectedStartTime = selectedItem.start_time;
    this.selectedEndTime = selectedItem.end_time;
    this.isScheduleEdit = true;
  }

  onEditSchedule() {
    const data = {
      id: this.selectedSchedule,
      agent: this.id,
      weekday: this.selectedWeekDay,
      start_time: this.selectedStartTime + "00",
      end_time: this.selectedEndTime + ":59"
    }

    this.usermanagementservice.updateAgentSchedule(data).subscribe((res: any) => {
      if (res.status === "1") {
        Swal.fire("Success", "Schedule updated successfully.", "success")
        this.selectedWeekDay = "6";
        this.selectedStartTime = "00:00";
        this.selectedEndTime = "23:59";
        this.isScheduleEdit = false;
        this.selectedSchedule = "";
        this.getAgentSchedules();

      } else {
        Swal.fire("Something went wrong!", "Failed to update schedule", "error")
      }
    })
  }

  OndeleteAgentSchedule(id: string) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The schedule will be deleted !",
      type: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it.",
      cancelButtonText: "No, keep it."
    }).then((result) => {
      if (!result.dismiss) {
        this.usermanagementservice.deleteAgentSchedule(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Schedule deleted successfully.", "success");
            this.getAgentSchedules();
          } else {
            Swal.fire("Something went wrong!", "Failed to delete the schedule.", "error");
          }
        })
      }
    })
  }

  onCancelEdit() {
    this.reloadCurrentRoute();
  }



  validateTime(): boolean {
    this.mandatoryError = false;

    if (!this.selectedStartTime || !this.selectedEndTime) {
      this.mandatoryError = true;
      this.timeError = false; 
      return false;
    }

    const start = this.convertToMinutes(this.selectedStartTime);
    const end = this.convertToMinutes(this.selectedEndTime);

    if (start > end) {
      this.timeError = true; 
      return false;
    }

    this.timeError = false; 
    return true; 
  }

  private convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes; 
  }

}
